import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { device } from '../lib/device';
import ContactData from '../hooks/getContactContent';
import '../style/app.scss';

const ContactTemplate = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	/* min-height: calc(100vh - 153px); */
	@media ${device.desktop} {
		grid-template-columns: 1fr;
	}
	.img_grid {
		position: relative;
		overflow: hidden;
	}
	.contact {
		padding: 30px 150px;
		@media ${device.desktopHhd} {
			padding: 30px 60px;
			.title {
				h1 {
					margin-bottom: 50px;
				}
			}
		}
		@media ${device.tablet} {
			padding: 30px 50px;
			.title {
				h1 {
					font-size: 40px;
				}
			}
		}
		@media ${device.mobile} {
			padding: 20px;
		}
		form {
			display: block;
			input,
			textarea {
				display: block;
				width: 100%;
				box-sizing: border-box;
				padding: 15px;
				padding-bottom: 5px;
				padding-left: 0;
				border: none;
				border-bottom: 1px solid #bcbcbc;
				outline: none;
				font-family: var(--font_harmoniasanspro_light);
				font-weight: 100;
				font-size: 20px;
				color: var(--brand_black);
				margin-bottom: 15px;
				&::-webkit-input-placeholder {
					color: #bcbcbc;
				}
				&::-moz-placeholder {
					color: #bcbcbc;
				}
				&:-ms-input-placeholder {
					color: #bcbcbc;
				}
				&:-moz-placeholder {
					color: #bcbcbc;
				}
			}
			.half {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 13px;
			}
			textarea {
				height: 140px;
				resize: none;
				border: 1px solid #bcbcbc;
				padding: 15px;
			}
			label {
				display: flex;
				align-items: center;
				font-family: var(--font_harmoniasanspro_light);
				line-height: 1.1;
				font-size: 16px;
				&.agreements {
					display: block;
				}
				@media ${device.desktop} {
				}
				a {
					color: var(--brand_blue);
					text-decoration: none;
					margin: 0 5px;
				}
				input {
					border-radius: 2px;
					border: 1px solid #bcbcbc;
					display: inline-block;
					width: auto;
					width: 16px;
					height: 16px;
					margin: 0;
					margin-right: 10px;
				}
			}
			p {
				font-family: var(--font_harmoniasanspro_light);
				font-weight: 100;
				font-size: 20px;
				margin-bottom: 5px;
				color: #bcbcbc;
			}
			.hidden {
				display: none;
			}
			button {
				outline: none;
				font-family: var(--font_harmoniasanspro_light);
				float: right;
				border: 1px solid var(--brand_blue);
				padding: 5px 20px;
				text-decoration: none;
				background-color: white;
				font-size: 20px;
				margin-top: 10px;
				color: var(--brand_blue);
				cursor: pointer;
				display: block;
				transition: all 0.4s ease;
				&:hover {
					background-color: var(--brand_blue);
					color: white;
				}
			}
			.contact_form_info {
				padding-top: 10px;
				overflow: auto;
				width: 100%;
				.success {
					color: green;
				}
				.error {
					color: red;
				}
			}
		}
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join('&');
}

const Contact = () => {
	const data = ContactData();
	const [mailSent, setMailSent] = useState(false);
	const [error, setError] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');

	function submitForm(e) {
		e.preventDefault();
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact', firstName, lastName, email, subject, message }),
		})
			.then(() => setMailSent(true))
			.catch((error) => setError(true));
	}
	return (
		<>
			<Header />
			<ContactTemplate>
				<Img fluid={data.featuredImage.node.localFile.childImageSharp.fluid} />
				<div className="contact">
					<div className="title">
						<h1>{data.title}</h1>
					</div>
					<form
						name="contact"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						action=""
						onSubmit={submitForm}
					>
						<input type="text" name="bot-field" className="hidden" />
						<div className="half">
							<input
								type="text"
								placeholder="First Name"
								name="firstName"
								onChange={(e) => setFirstName(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Last Name"
								name="lastName"
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>
						<input
							type="email"
							placeholder="Email"
							name="email"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<input
							type="subject"
							placeholder="Subject"
							name="subject"
							onChange={(e) => setSubject(e.target.value)}
						/>
						<p>Message</p>
						<textarea name="message" onChange={(e) => setMessage(e.target.value)} />
						<label htmlFor="agreements" className="agreements">
							<input type="checkbox" name="agreements" id="agreements" required />I have read and agree
							with <Link to="/terms-and-conditions">Privacy Policy & Terms and Condititions.</Link>
						</label>
						<button type="submit">Send</button>
						<div className="contact_form_info">
							{mailSent ? <div className="success">Email successfully sent!</div> : ''}
							{error ? <div className="error">There was an error, please try again later.</div> : ''}
						</div>
					</form>
				</div>
			</ContactTemplate>
			<Footer />
		</>
	);
};
export default Contact;
