import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const ContactData = () => {
	const data = useStaticQuery(graphql`
		{
			wpPage(id: { eq: "cG9zdDoxNjk=" }) {
				title
				featuredImage {
					node {
						localFile {
							childImageSharp {
								fixed {
									...GatsbyImageSharpFixed
								}
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
				seo {
					canonical
					cornerstone
					focuskw
					metaDesc
					metaKeywords
					metaRobotsNofollow
					metaRobotsNoindex
					opengraphAuthor
					opengraphDescription
					opengraphModifiedTime
					opengraphPublishedTime
					opengraphPublisher
					opengraphSiteName
					opengraphTitle
					opengraphType
					opengraphUrl
					title
					twitterDescription
					twitterTitle
					twitterImage {
						localFile {
							url
						}
					}
					opengraphImage {
						localFile {
							url
						}
					}
				}
			}
		}
	`);
	return data.wpPage;
};

export default ContactData;
